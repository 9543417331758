import { APP_BASE_HREF } from "@angular/common";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import {
  ApplicationModule,
  ErrorHandler,
  Injectable,
  NgModule,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";

import { CoreModule } from "@core/core.module";
import { AccessInterceptor } from "@core/services/access-interceptor";
import { SuperadminInterceptor } from "@core/services/proctor-interceptor";
import { SessionInterceptor } from "@core/services/session-interceptor";

import { ThemeModule } from "@theme/theme.module";

import * as Sentry from "@sentry/browser";

import { environment } from "../environments/environment";
import { BUILD_REV } from "../environments/version";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthGuardService } from "./auth-guard.service";
import { SharingInterceptor } from "./sharing/sharing-interceptor";

Sentry.init({
  dsn: "https://5818337c7c804f30b8d45b9b87bb1f43@sentry.io/1852644",
  release: BUILD_REV,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.error(error);
    }
  }
}

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    ApplicationModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ThemeModule.forRoot(),
    CoreModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.serviceWorker,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: "registerWhenStable:30000",
    }),
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: "/" },
    // { provide: ErrorHandler, useClass: SentryErrorHandler },
    AuthGuardService,
    { provide: HTTP_INTERCEPTORS, useClass: SharingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SuperadminInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessInterceptor,
      multi: true,
    },

    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
