import { ApplicationRef, Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";

import { concat, interval } from "rxjs";
import { first } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class CheckForUpdateService {
  constructor(appRef: ApplicationRef, updates: SwUpdate) {
    const appIsStable$ = appRef.isStable.pipe(
      first((isStable) => isStable === true),
    );

    const everyFiveMinutes$ = interval(60 * 5 * 1000);
    const everyFiveMinutesOnceAppIsStable$ = concat(
      appIsStable$,
      everyFiveMinutes$,
    );

    everyFiveMinutesOnceAppIsStable$.subscribe(async () => {
      try {
        if (updates.isEnabled) {
          await updates.checkForUpdate();
        }
      } catch (err) {
        console.error("Failed to check for updates:", err);
      }
    });
  }
}
